body {
  margin: 0;
  font-family: $open-sans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  color: $default-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Medieval';
  src: url('./MedievalSharp-Bold.ttf') format('truetype');
}

a,
button {
  cursor: pointer;
  color: $link;
  &:hover {
    color: $link-hover;
  }
  &:active,
  :focus {
    color: $link-active;
  }
}
p {
  font-size: 1.1rem;
}
.cta-btn {
  display: flex;
  align-items: center;
}

.container {
  position: relative;
  display: grid;
  justify-items: center;
  background-color: $background;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'main'
    'footer';

  header {
    width: 100%;
    max-width: 100vw;
  }
  main {
    margin-top: 5.5rem;
    grid-area: main;
  }
  footer {
    grid-area: footer;
    padding: 2rem;
    font-size: 1.2rem;
    width: 100%;
    max-width: 100vw;
  }
}
