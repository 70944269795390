footer {
  position: absolute;
  bottom: 0px;
  & p {
    font-size: 1.1rem;
    margin-top: 1.5rem;
  }
  & a {
    display: block;
  }
}
