.favicon {
  height: 1.5rem;
  width: 1.5rem;
  margin-bottom: 0;
}

.header {
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw; //comment is for when nav was grid and not flex --> without this grid-items don't wrap and overflow outside viewport
  width: 100%; //comment is for when nav was grid and not flex --> this plus max-width forces header to take up whole viewport
  margin: 0;
  padding: 0 1.5rem;
  font-size: 1.5rem;
  transition: all 0.3s ease, height 0.3s cubic-bezier(0.25, 0.25, 0.4, 1);
  z-index: 3;
  &.scroll {
    background-color: $grey-10;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    .nav {
      height: $header-height;
      .brand {
        a {
          color: $primary;
          text-decoration: none;
          &:hover {
            color: $green-8;
          }
          &:active {
            color: $green-8;
          }
        }
      }
    }
  }
  .nav {
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: space-between;
    max-width: $width; //forces nav to specified width
    height: 5.5rem;
    margin: auto;
    padding: 0 1.5rem;
    transition: all 0.3s ease;

    .brand {
      display: flex;
      align-items: center;
      font-family: 'Medieval', 'Arial';
      a {
        display: flex;
        align-items: center;
        color: $primary;
        font-size: 1.5rem;
        &:hover {
          color: $green-6;
        }
        &:active {
          color: $green-9;
        }
      }
      span.text {
        white-space: nowrap;
      }
    }

    .links {
      display: flex;
      align-items: center;
      margin-left: auto; //flex item will align to the right since you can't use justify-items or justify-self with flexbox
      a {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 0;
        padding: 1rem 0.75rem;
      }
    }
  }
}

button.dark-switcher {
  margin: 0;
  border: 0;
  padding: 1rem;
  background: transparent;
  &:focus {
    outline: none;
  }
}

.theme-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-bottom: 0;
}
