.main-grid {
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  display: grid;
  position: relative;
  background-color: $paper-bg-color;
  grid-template-areas: 'options canvas';
  grid-template-columns: minmax(min-content, max-content) minmax(
      min-content,
      max-content
    );
  grid-template-rows: 1fr;
  position: relative;
  grid-gap: 1.5rem;
  justify-items: center;
  .aside-wrapper {
    box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
    grid-area: options;
    background-color: $grey-8;
    .aside {
      justify-self: stretch;
      min-height: 0;
      align-self: start;
    }
  }

  .canvases-wrapper,
  .images-wrapper {
    grid-row-gap: 1rem;
    min-width: 0;
    min-height: 0;
    grid-area: canvas;
    display: grid;
    margin-top: 1.15rem;
    justify-items: center;
    align-items: center;
    .canvas-item,
    .img-item {
      min-width: 0;
    }
  }
}

@media all and (max-width: 1067px) {
  .main-grid {
    .aside-wrapper {
      width: 100%;
      text-align: center;
      .aside {
        display: inline-block;
        max-width: 820px;
      }
    }
    grid-gap: 1rem;
    grid-template-areas:
      'options'
      'canvas';
    grid-template-columns: minmax(300px, 1fr);
  }
}

@media all and (min-width: 820px) {
  .main-grid {
    .aside {
      position: sticky;
      top: $header-height;
    }
  }
}

@media all and (min-width: 1920px) {
  .main-grid {
    max-width: $max-canvas-width;
  }
}

@media all and (min-width: 1067px) {
  .canvases-wrapper,
  .images-wrapper {
    margin-right: 1.5rem;
  }
}
