.dark.container {
  $form-bg-color: $grey-9;
  $checked-color: $blue-4;
  $hover-bg-color: $grey-10;
  $paper-bg-color: $grey-9;
  background-color: $dark-background;
  color: $default-dark-color;

  a {
    color: $blue-4;
    &:hover {
      color: lighten($blue-4, 20%);
    }
    &:active {
      color: darken($blue-4, 20%);
    }
  }

  .header {
    .nav {
      .brand {
        a {
          color: $primary;
          &:hover {
            color: $green-5;
          }
          &:active {
            color: $green-8;
          }
        }
      }
    }
  }

  .main-grid {
    .aside-wrapper {
      background-color: $grey-5;
    }
    background-color: $paper-bg-color;
  }

  .upload-btn {
    label,
    button {
      .cloud-cover {
        background-color: $form-bg-color;
      }
    }
  }
  .options,
  form {
    color: $default-dark-color;
    background-color: $form-bg-color;
    .form-group {
      .form-check-label,
      .form-radio-label {
        .cb-span {
          border: $checkbox-height * 0.1 solid $default-dark-color;
        }
        .radio-span {
          box-shadow: 0 0 0 0.125em $default-dark-color;
          border-color: $form-bg-color;
        }
        &:hover {
          background-color: $hover-bg-color;
          .radio-span {
            border-color: $hover-bg-color;
          }
        }
      }
      input[type='checkbox'],
      input[type='radio'] {
        &:checked {
          + .cb-span {
            border-color: $checked-color;
            &::before,
            &::after {
              background-color: $checked-color;
            }
            &::before {
              box-shadow: 0 0 0 $checkbox-height * 0.05 $form-bg-color;
            }
          }
        }
      }
      .form-check-label {
        &:hover {
          input[type='checkbox'],
          input[type='radio'] {
            + .cb-span {
              &::before {
                box-shadow: 0 0 0 $checkbox-height * 0.05 $hover-bg-color;
              }
            }
          }
        }
      }
    }
  }
}
