$duration: 0.4s;
$radio-height: 1.25rem;
.options,
.form {
  background-color: $form-bg-color;
  margin: 3rem auto;
  min-width: 250px;
  max-width: 32rem;
  padding: 0 1rem;
  color: $font-color;
  height: max-content;
  margin: 1rem;

  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  .form-group {
    font-size: 1em;
    padding: 1em;
    border-radius: $border-radius;
    align-content: center;
    align-items: flex-start;
    vertical-align: center;

    .form-check-label,
    .form-radio-label,
    .form-text-label {
      display: block;
      white-space: nowrap;
      cursor: pointer;
      font-size: 1.15rem;
      padding: 1rem;
      text-align: left;
      span {
        white-space: nowrap;
      }
      .cb-span,
      .radio-span {
        vertical-align: -0.25rem;
        margin-right: 0.75rem;
        background-color: transparent;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        cursor: pointer;
      }
      .cb-span {
        transition: border-color ease $duration/2;
        height: $checkbox-height;
        width: $checkbox-height;
        border: $checkbox-height * 0.1 solid $unchecked-color;
        border-radius: $border-radius - 1;
      }

      .radio-span {
        box-shadow: 0 0 0 0.125em $grey-9;
        border: $checkbox-height * 0.15 solid $form-bg-color;
        height: $radio-height;
        width: $radio-height;
        border-radius: 50%;
        transition: all $duration cubic-bezier(0.4, 0, 0.23, 1),
          border-color ease 0ms;
        background-color: transparent;
      }
      &:hover {
        background-color: $hover-bg-color;
        .radio-span {
          border-color: $hover-bg-color;
        }
      }
      input[type='checkbox'],
      input[type='radio'] {
        display: none;
        &:checked {
          + .radio-span {
            box-shadow: 0 0 0 0.125em $checked-color;
            background-color: $checked-color;
            animation: shrink-bounce $duration cubic-bezier(0.4, 0, 0.23, 1);
          }
          + .cb-span {
            animation: shrink-bounce $duration cubic-bezier(0.4, 0, 0.23, 1);
            animation-delay: $duration/1;
            border-color: $checked-color;
            &::before,
            &::after {
              content: ' ';
              box-sizing: border-box;
              position: absolute;
              height: 0;
              width: $checkbox-height * 0.2;
              background-color: $checked-color;
              display: inline-block;
              transform-origin: left top;
              border-radius: $border-radius - 2;
            }
            &::after {
              top: $checkbox-height * 0.37;
              left: $checkbox-height * 0.05;
              transform: rotate(-45deg);
              animation: bottomcheck $duration/3 ease 0s forwards;
            }

            &::before {
              top: $checkbox-height * 0.72;
              left: $checkbox-height * 0.41;
              box-shadow: 0 0 0 $checkbox-height * 0.05 $form-bg-color;
              transform: rotate(-135deg);
              animation: topcheck $duration/1.5 ease 0s forwards;
            }
          }
        }
      }
    }
    .form-text-label {
      .form-text-helper {
        font-size: 0.75rem;
        text-align: left;
      }
      &:hover {
        background-color: inherit;
      }
      text-align: center;
      .input-text-wrapper {
        margin-top: 0.5rem;
        position: relative;
      }
      input[type='text'] {
        padding: 0.3rem 0.5rem;
        min-width: 200px;
        width: 100%;
        box-sizing: border-box;
      }

      .input-text {
        border: 0;
        padding: 7px 0;
        border-bottom: 2px solid $grey-4;
      }

      .input-text ~ .focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $checked-color;
        transition: 0.4s;
      }

      .input-text:focus ~ .focus-border {
        width: 100%;
        transition: 0.4s;
      }
    }
    .form-check-label {
      &:hover {
        input[type='checkbox'],
        input[type='radio'] {
          + .cb-span {
            &::before {
              box-shadow: 0 0 0 $checkbox-height * 0.05 $hover-bg-color;
            }
          }
        }
      }
    }
  }
}

.aside {
  .options,
  .form {
    // background-color: inherit;
    box-shadow: none;
  }
}

@keyframes bottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: $checkbox-height/2;
  }
}

@keyframes topcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: $checkbox-height * 1.2;
  }
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
// ------

@media all and (max-width: 450px) {
  .options,
  .form {
    .form-group {
      padding: 1em;
    }
  }
  .options {
    padding: 0;
    fieldset {
      padding: 3em 2.3em;
    }
  }
  .aside {
    .options {
      padding: 0;
    }
  }
}

input[type='text'] {
  font: 15px/24px 'Muli', sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

:focus {
  outline: none;
}
