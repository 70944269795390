$white: #ffffff;
$jet-black: #000000;
// https://coolors.co/3682ed-27ae60-080708-df2935-fdca40
/*  greens Primary "green" */

$green: #27ae60;
$green-1: mix(white, $green, 80);
$green-2: mix(white, $green, 60);
$green-3: mix(white, $green, 40);
$green-4: mix(white, $green, 20);
$green-5: $green;
$green-6: mix(black, $green, 20);
$green-7: mix(black, $green, 40);
$green-8: mix(black, $green, 60);
$green-9: mix(black, $green, 80);

//greys
$grey: #a7b6ad;
$grey-0: #f8f9f9;
$grey-1: mix(white, $grey, 80);
$grey-2: mix(white, $grey, 60);
$grey-3: mix(white, $grey, 40);
$grey-4: mix(white, $grey, 20);
$grey-5: $grey;
$grey-6: mix(black, $grey, 20);
$grey-7: mix(black, $grey, 40);
$grey-8: mix(black, $grey, 50);
$grey-9: mix(black, $grey, 60);
$grey-10: mix(black, $grey, 70);
$grey-11: mix(black, $grey, 80);
$grey-12: mix(black, $grey, 90);

/* blues */
$blue: #3682ed;

$blue-1: mix(white, $blue, 80);
$blue-2: mix(white, $blue, 60);
$blue-3: mix(white, $blue, 40);
$blue-4: mix(white, $blue, 20);
$blue-5: $blue;
$blue-6: mix(black, $blue, 20);
$blue-7: mix(black, $blue, 40);
$blue-8: mix(black, $blue, 60);
$blue-9: mix(black, $blue, 80);

/* Yellows */
$yellow: #fdca40;
$yellow-1: mix(white, $yellow, 80);
$yellow-2: mix(white, $yellow, 60);
$yellow-3: mix(white, $yellow, 40);
$yellow-4: mix(white, $yellow, 20);
$yellow-5: $yellow;
$yellow-6: mix(black, $yellow, 20);
$yellow-7: mix(black, $yellow, 40);
$yellow-8: mix(black, $yellow, 60);
$yellow-9: mix(black, $yellow, 80);
/* Hightlighter */
// $yellow-hl: #f1c40f;
// $yellow-hl: #f0d461;
// #FFD806
// $red-hl: #ef5b5b;
// $green-hl: #00d287;
// $blue-hl: #5183f5;
