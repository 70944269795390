@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

$max-canvas-width: 2000px;
$width: 1450px;
$header-height: 3.5rem;
$open-sans: 'Open Sans', sans-serif;
$primary: $green;
$font-color: $grey-11;

/* Paper */
$paper-bg-color: $grey-5;
/* Buttons & forms */
/* Forms */
$checked-color: $blue-5;
$unchecked-color: $font-color;
$checkbox-height: 1.25rem;
$hover-bg-color: $grey-2;
$form-bg-color: $grey-1;

/* Buttons */
$border-radius: 4px;
$button: $primary;
$hover: $green-7;
$button-hover: $green-7;

:export {
  width: $width;
}

/* Loaders  */

$loader-color: #0052ec !default;
$loader-size: 56px !default;
$loader-height: 20px !default;
$loader-border-size: 8px !default;
$loader-gap: 12px !default;
$loader-animation-duration: 1s !default;

/* Forms */
$fieldset-border: $blue-9;
$fieldset-bg: rgba(lighten($blue-5, 25%), 0.85);

$dark-background: $grey-11;
$background: $white;
$default-color: $grey-7;
$default-dark-color: $grey-1;
$primary: $green-5;
$primary-dark: $green-5;

/* Links */
$link: $blue-7;
$link-hover: $blue-4;
$link-active: $blue-8;
